.inputField {
    width: 100%;
    border: 1px solid #A5A7C2;
    padding: 10px 16px;
    outline: none;
    &:hover, &:focus {
        border: 1px solid #008BEF;
    }
    &.textarea {
        height: 100px;
        max-width: 100%;
        min-width: 100%;
    }
}