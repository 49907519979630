.macroList {
  li.macroDetails {
    .macroInfo
    img {
      cursor: pointer !important;
    }
  }
  &.macroListItems{
    width: 100%;
  }
  overflow: scroll;
  .macroDetails {
    padding: 17px 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
    border: 1px solid rgba(165, 167, 194, 0.5);
    .macroInfo {
      min-width: calc(100% - 340px);
      width: calc(100% - 340px);
      padding-right: 20px;
      align-items: unset;
      .hideOverflow {
        overflow: hidden;
        flex: 1;
      }
      img {
        width: 64px;
        margin-right: 16px;
        height: 64px;
        cursor: text;
        overflow: hidden;
        &:hover + div .macroDetailsLink span {
          color: $linkColor;
        }
      }
      .ellipsis {
        @include ellipsis();
      }
      .macroName {
        width: 100%;
        .authorName {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 15%;
        }
        .gameName {
          max-width: 200px;
          margin-right: 5%;
          flex-shrink: 0;
          min-width: 100px;
        }
      }
    }
    .macroDownload {
      padding-left: 20px;
      border-left: 1px solid rgba(165, 167, 194, 0.2);
      .smallIcon {
        width: 16px;
        height: 16px;
        background-size: contain;
        &.downloads {
          @include bgImage("../../images/download_icon.png");
        }
        &.comments {
          @include bgImage("../../images/comments_icon.png");
        }
        &.likes {
          @include bgImage("../../images/heart_icon.png");
          &.liked {
            @include bgImage("../../images/heart_icon_liked.svg");
          }
        }
      }
      .downloadButton {
        padding: 8px 26px;
        margin-left: 7px;
      }
      .truncate{
        max-width: 90px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  li {
    &.macroDetails {
      .macroInfo {
        align-items: center;
      }
    }
  }
}
.macroDesc{
  min-height: 180px;
  border-bottom: 1px solid #e7e9ee;
  white-space: pre-wrap;
}

@media only screen and (max-width: 480px) {
  .searchResultsSection {
    flex-direction: column;
  }
  .macroName {
    flex-direction: column !important;
    span {
      width: 100% !important;
    }
  }
}
