header {
  padding: 16px 0px 20px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  .communityLink {
    width: 25%;
    padding-right: 40px;
  }
  .communityIcon {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    @include bgImage("../../images/macro_community_icon.png");
    background-size: cover;
  }
  .uploadIcon {
    width: 15px;
    height: 15px;
    &.upload {
      @include bgImage("../../images/upload_icon.svg");
    }
    &.myUploads {
      @include bgImage("../../images/my_macros_icon.svg");
      &_active {
        @include bgImage("../../images/my_macros_icon_active.svg");
      }
    }
  }
  .helpIcon{
    width: 20px;
    height: 20px;
    @include bgImage("../../images/help_icon.png");
    &:hover{
      @include bgImage("../../images/help_icon_hover.png");
    }
  }
  .linksContainer {
    display: flex;
    align-items: center;
    width: 25%;
    justify-content: flex-end;
  }
  .searchLinks span {
    padding: 0px 10px;
    cursor: pointer;
    border-left: 1px solid #979797;
    &:first-child {
      border: none;
    }
  }

  .truncate{
    max-width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.subHeaderSection{
  position:sticky;
  justify-content: space-between;
  background: #fff;
  z-index: 1;

  .sortByText{
    font-size: 14px;
    line-height: 16px;
    color: #777995;
    margin-right: 12px;
  }

  .sortByList{
    border: 1px solid #d2d4e0;
    border-radius: 5px;
    font-size: 14px;
    line-height: 1;
    width: 170px;
    select {
      width: 100%;
      &.disabled {
        opacity: 0.5;
      }
      option {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .MuiSelect-select:focus{
     background-color: #ffffff !important;
     border-radius: 5px;
   }
  .MuiSelect-selectMenu{
    padding-left: 10px;
    max-width: 170px;
  }
  .MuiInput-underline:before{
    border-bottom: 0;
    transition: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: 0;
    transition: none;
  }
  .MuiInput-underline:after{
    border-bottom: 0;
    transition: none;
  }
}

 .headerSelectItem{
   font-size: 14px !important;
   line-height: 1 !important;
   font-family: "Segoe UI", sans-serif !important;
 }

 .headerSelectItem.MuiListItem-button:hover {
  background-color: #F5F5F5 !important;
}
@media only screen and (max-width: 900px) {
  .header {
    flex-wrap: wrap;
    justify-content: center !important;
    .communityLink {
      width: auto;
    }
    .linksContainer {
      width: auto;
    }
  }
}

@media only screen and (max-width: 480px) {
  .header {
    .communityLink {
      margin-bottom: 20px;
    }
  }
}


