.modalContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
}

.modalBody {
  width: 420px;
  background: #fff;
  padding: 20px;
  z-index: 11;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .saveChangesBtn {
    padding: 10px 30px;
  }
  &.signInModal {
    padding: 20px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 12px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .bsLogo {
      width: 32px;
      margin-right: 10px;
    }
    header {
      h2 {
        color: $textColordark;
        font-size: 20px;
      }
    }
    .loginImg {
      width: 100px;
      margin: 25px;
    }
    h3 {
      font-size: 18px;
      color: $textColorLight;
    }
    .signInBtn {
      background-color: #4285f4;
      border-radius: 2px;
      margin: 20px 0 6px 0;
      .sign-in-button {
        display: flex;
        align-items: center;
        padding: 0 15px 0 0;
      }
    }
    .googleLogo {
      height: 45.6px;
      width: 45.6px;
      border-radius: 1.2px;
      background-color: #ffffff;
      margin: 1px 10px 1px 1px;
    }
    h6 {
      color: #9b9b9b;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      text-align: center;
      text-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
      width: 80%;
      margin: 15px auto 8px;
    }
  }
}

.cancelBtn {
  color: #1e213d;
  padding: 8px 15px;
  background-color: $white;
  border: 1px solid $linkColor;
}

.continueButton {
  padding: 8px 17px;
}

.errorClass{
  border: 1px solid #FF402F !important;
}

@media only screen and (max-width: 480px) {
  .modalBody,
  .downloadModal {
    width: calc(100% - 20px);
    margin: 0 auto;
  }
}
