@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    .#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@mixin abs-position($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin circle($size) {
  width: $size;
  height: $size;
  border-radius: 100% !important;
}

@mixin border-radius($radius) {
  border-radius: $radius;
}

@mixin line-height($height) {
  line-height: $height;
}

@mixin truncate($trunkColor) {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  &:after {
    content: "";
    width: 30px;
    height: 100%;
    @include abs-position(unset, 0, unset, unset);
    @include themify($themes) {
      background: themed($trunkColor);
    }
  }
}

@mixin animate($name, $duration: 3000ms, $count: infinite, $function: linear) {
  animation-name: $name;
  animation-duration: $duration;
  animation-iteration-count: $count;
  animation-timing-function: $function;
}

@mixin keyframe($animation_name) {
  @keyframes #{$animation_name} {
    @content;
  }
}

@include keyframe(spin) {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin relative-pos {
  position: relative;
}

@mixin ellipsis($width: 100%) {
	max-width: $width;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin bgImage($url) {
  background-image: url($url);
  background-repeat: no-repeat;
  flex-shrink: 0;
}

$spaceamounts: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  50,
  60,
  70,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .margin-#{$side}-#{$space} {
      margin-#{$side}: #{$space}px;
    }

    .padding-#{$side}-#{$space} {
      padding-#{$side}: #{$space}px;
    }
  }
}

$fontSizes: (
  10,12,13,14,16,18,20,22,24,26,28,30,32,34,36,38,40
);

@each $size in $fontSizes {
    .font#{$size} {
      font-size: #{$size}px;
      line-height: #{$size*1.4}px;
    }
}


