.seperator {
  width: 1px;
  height: 20px;
  background-color: #A5A7C2;
}

.macroDetails {
  .description {
    flex-grow: 1;
    justify-content: space-between;
  }
  .macroDetailsContainer {
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(165, 167, 194, 0.2);
    .macroDownload {
      border: none;
    }
  }
}

.backArrow {
  border-left: 1px solid #008bef;
  border-bottom: 1px solid #008bef;
  transform: rotate(45deg);
  width: 9px;
  height: 9px;
  margin-right: 7px;
  left: 2px;
  @include relative-pos();
}

.deleteBtn {
  border: 1px solid gray;
  color: gray;
  &:hover {
    border-color: $warningColor;
    color: $warningColor;
  }
}

.editBtn {
  border: 1px solid $darkBluetext;
  color: $darkBluetext;
  padding: 2px 12px;
  font-size: 14px;
}

.buttonContainer button {
  padding: 2px 12px;
  font-size: 14px;
}

.macroDetailsLink {
  &:hover {
    span {
      color: $linkColor;
    }
  }
}

@media only screen and (max-width: 850px) {
  .macroDetails {
    flex-wrap: wrap;
    .macroDownload {
      border: none !important;
      padding: 20px 0 0 0 !important;
      margin: 0 auto;
    }
    .macroInfo {
      border-bottom: 1px solid rgba(165, 167, 194, 0.2);
      padding-bottom: 20px;
      width: 100% !important;
    }
    .macroDetailsContainer {
      flex-wrap: wrap;
    }
    .timestamp {
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .downloadDetails {
    flex-wrap: wrap;
    justify-content: center;
    .downloadButton {
      margin: 20px auto 0  !important;
    }
  }
  .buttonContainer {
    align-items: center;
  }
}
