.uploadMacro {
  margin: 40px auto 20px;
  width: 560px;
  overflow: scroll;
  .selectBoxContainer{
    .MuiSelect-select:focus{
      background-color: #ffffff !important;
  }
    .MuiSelect-selectMenu{
      padding-left: 10px;
      max-width: 220px;
    }
    .MuiInput-underline:before{
      border-bottom: 0;
      transition: none;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before{
      border-bottom: 0;
      transition: none;
    }
    .MuiInput-underline:after{
      border-bottom: 0;
      transition: none;
    }
    .Mui-focused{
      border: 0;
    }
    
  }
  .uploadMacroContainer {
    padding: 16px 20px 24px;
    border: 1px solid rgba(165, 167, 194, 0.5);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    .bsMacroList {
      width: calc(100% - 20px);
      height: 40px;
      border: 1px solid #d2d4e0;
      border-radius: 5px;
    }
    .selectBtn{
      height: 25px;
      width: 91px;
      border: 1px solid #A5A7C2;
      border-radius: 5px;
      background: linear-gradient(180deg, #F9F9F9 0%, #F1F1F1 100%);
      box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);
      color: #232642;
      font-family: "Segoe UI";
      font-size: 14px;
      line-height: 19px;
    }
    .editNickNameLink {
      .editIcon {
        height: 20px;
        width: 18px;
        @include bgImage('../../images/edit_profile.svg');
      }
      &:hover {
        .editIcon {
          @include bgImage('../../images/edit_profile_active.svg');
        }
      }
    }
    .submitMacroBtn {
      padding: 9px 29px;
    }
    .selectFile {
      width: calc(100% - 20px);
    }
    .fileWidth{
      width: 103px;
    }
    .checkboxContainer {
      position: relative;
      .subscribeCheckbox {
        opacity: 0;
        width: 12px;
        height: 12px;
        z-index: 1;
        cursor: pointer;
        &:checked + .fakeCheckBox {
          background: $linkColor;
          border: 1px solid $linkColor;
          &:after {
            content: "";
            position: absolute;
            width: 2px;
            height: 5px;
            top: 1px;
            left: 3px;
            transform: rotate(45deg);
            border-bottom: 2px solid $white;
            border-right: 2px solid $white;
          }
        }
      }
      .fakeCheckBox {
        width: 12px;
        height: 12px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 2px;
        border: 1px solid $lightGreyColor;
      }
    }
  }
  .selectBoxContainer {
    width: 100%;
    select {
      width: 100%;
      &.disabled {
        opacity: 0.5;
      }
      option {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .chooseFile{
    display: inline-flex;
    input[type='file'] {
      color: transparent;
    }
    label{
      padding-left: 10px;
      max-width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #232642;
      font-family: "Segoe UI";
      font-size: 16px;
      line-height: 21px;
    }
  }

}

select {
  border: 1px solid #a5a7c2;
  border-radius: 0px;
  outline: none;
  height: 40px;
}

.macroSuccess{
  padding: 15px;
  margin: 10px auto;
  border: 1px solid rgba(0,139,239,0.3);
  background-color: #EBF7FF;
  width: 432px;;
  box-shadow: 0 4px 8px 0 rgba(0,139,239,0.14);
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 10px);
  z-index:2;
  .msgSuccess{
    color: #00243D;
  }
  .closeMsg{
    float: right;
    cursor: pointer;
  }
}
.errorList{
  border: 1px solid red !important;
}

@media only screen and (max-width: 800px) {
  .uploadMacro {
    width: 100%;
    margin: 5% auto 20px;
  }
}

@media only screen and (max-width: 480px) {
  .radioBoxContainer {
    flex-direction: column;
  }
  .macroSource {
    margin-bottom: 10px;
  }
}
.greySeperator{
  border-top: 1px solid #d2d2e0;
}
.hiddenOption{
  display: none !important;
}
.MuiListItem-button{
  white-space: normal !important;
  word-wrap: break-word !important;
  word-break: break-all;
}
.Mui-selected{
  background: #ffffff !important;
}
.MuiListItem-button:hover {
  background-color: #2698f1 !important;
}
  