.primaryBtn {
  opacity: 0.9;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  background-color: #008BEF;
  &:hover {
    opacity: 1;
  }
}
