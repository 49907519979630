.Assets * {
  border-radius: 0 !important;
}

.container {
  padding-bottom: 20px;
  background-color: $white;
  min-height: 100vh;
}

.appHome {
  max-width: 1218px;
  padding: 0 10px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

select {
  width: 100%;
}

.commonSection {
  border: 1px solid rgba(165, 167, 194, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  width: 560px;
  padding: 4% 8%;
  margin: 12% auto 0;
  .uploadNowBtn {
    padding: 8px 50px;
  }
}

.crossIcon {
  width: 24px;
  height: 24px;
  @include relative-pos();
  &:after,
  &:before {
    position: absolute;
    content: "";
    width: 2px;
    height: 24px;
    background-color: #d8d8d8;
    left: 14px;
  }
  &:after {
    transform: rotate(45deg);
  }
  &:before {
    transform: rotate(-45deg);
  }
}

.count, .disqus-comment-count {
  width: 35px;
}

#downloadMacro {
  display: none;
}

@media only screen and (max-width: 800px) {
  .commonSection {
    width: 100%;
    margin: 5% auto 20px;
  }
}
.noWrapText{
  white-space: nowrap;
}
