.allGamesItem{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    min-width: 292px;
    width: calc(33.33% - 10px);
    border: 1px solid rgba(165, 167, 194, 0.5);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    flex-shrink: 0;
    margin-left: 10px;
    cursor:pointer;

    .gameImg{
        width: 64px;
        height: 64px;
    }

    .textGroup{
        padding: 6px 12px 0px 12px;
        width: calc(100% - 64px);
        height: 100%;

        .gameTitle{
            font-size: 14px;
            color: #000000;
        }
        .macroCount{
            font-size: 12px;
            line-height: 14px;
            color: #777995;
        }

        .truncate{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.gameListContainer{
    display: flex;
    width: calc(100% - 242px);
    flex-wrap: wrap;
    margin-right: 2px;
}