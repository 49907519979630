.flex {
  display: flex;
  &.column {
    flex-direction: column;
  }
  &.hrtlCenter {
    justify-content: center;
  }
  &.vrtlCenter {
    align-items: center;
  }
  &.perfectCenter {
    justify-content: center;
    align-items: center;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.noWrap {
    flex-wrap: nowrap;
  }
  &.spaceBetween {
    justify-content: space-between;
  }
  &.end {
    align-items: flex-end;
  }
  &.start {
    align-items: flex-start;
  }
  &.alignRight {
    justify-content: flex-end;
  }
  .noShrink {
    flex-shrink: 0;
  }
  .grow {
    flex: 1;
  }
}
