$defaultBgColor: #20223a;
$blueBgColor: #003659;
$pinkBgColor: #400f1a;
$lineageBgColor: #33230f;
$white: #fff;
$textColordark: #232642;
$textColorLight: #464A75;
$lightGreyColor: #A5A7C2;
$linkColor: #008BEF;
$mediumGreyColor: #777995;
$darkBluetext: #121429;
$warningColor: #FF402F;
$orange: #F09200;