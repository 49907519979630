@charset "UTF-8";

.bold {
  font-weight: 600;
}

.bolder {
  font-weight: 900;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.textLeft {
  text-align: left;
}

.pushRight {
  margin-left: auto;
}

.pointer {
  cursor: pointer;
}

.textColorHeading {
  color: $textColordark;
}
.textColorSubHeading {
  color: $textColorLight;
}
.lightGreyColor {
    color: $lightGreyColor;
}
.linkColor {
    color: $linkColor;
}
.uppercase {
  text-transform: uppercase;
}
.mediumGreyColor {
  color: $mediumGreyColor;
}
.darkBluetext {
  color: $darkBluetext;
}
.capitalize {
  text-transform: capitalize;
}
.warningText {
  color: $warningColor !important;
}
.whiteText {
  color: #fff;
}

.orangeText {
  color: $orange;
}
