.hideSpace {
  position: absolute;
  background: white;
  &.shareLinks {
    top: 50px;
    width: 335px;
    height: 25px;
  }
}

#disqus_thread {
  position: relative;
}

@media only screen and (max-width: 530px) {
  .hideSpace {
    &.shareLinks {
      width: 100px;
    }
    &.hideUser {
      width: 70px;
    }
    &.hideSubscribe {
      bottom: 20px;
      height: 25px;
      width: 100px;
    }
  }
}
