.popularGamesContainer{
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-left: 1px solid rgba(165, 167, 194, 0.5);
    border-right: 1px solid rgba(165, 167, 194, 0.5);
    border-top: 1px solid rgba(165, 167, 194, 0.5);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 8px 8px rgba(0, 0, 0, 0.04), 0px 16px 16px rgba(0, 0, 0, 0.04);
    font-size: 14px;
    line-height: 16px;
    max-width:220px;
    min-width:220px;
  
    .gameImg{
      width:24px;
      height: 24px;
    }
    .itemGroup{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &.item:hover{
        background-color: #F5F5F5;
      }
    }
    .item{
      border-bottom: 1px solid rgba(165, 167, 194, 0.5);
      padding: 9px 12px;
    }
    .header{
      font-weight: 900;
      color: #121429;
    }
    .body
    {
      max-height: 260px;
      // min-height: 100px;
      overflow: auto;
      cursor: pointer;
    }
    .footer{
      display: flex;

      .viewAllGamesLink{
        margin: 0 auto;
        background: none!important;
        border: none;
        padding: 0!important;
        color: #069;
        text-decoration: none;
        cursor: pointer;
      }
    }
    .title{
      max-width: 123px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000000;
      padding-left: 8px;
      padding-top: 3px;
    }
    .commentsCount{
      font-size: 12px;
      line-height: 14px;
      color: #777995;
      padding-top: 3px;
      margin-left: 13px;
    }
  }