.searchBox {
  width: calc(50% - 60px);
  form {
    width: 100%;
  }
  .searchInput {
    width: 100%;
    padding: 10px 16px;
    border: 1px solid #a5a7c2;
    border-right: none;
  }
  .searchIcon {
    background-size: 45px;
    width: 45px;
  }
  .searchButtonIcon {
    @include bgImage("../../images/search_enabled.png");
    &:hover {
      @include bgImage("../../images/search_hover.png");
    }
  }
  .searchButtonIconDisabled {
    border: 1px solid #a5a7c2;
    @include bgImage("../../images/search_disabled.png");
  }
}

@media only screen and (max-width: 900px) {
  .searchBox {
    order: 3;
    margin-top: 20px;
    width: 50%;
    margin-right: 0;
  }
}

@media only screen and (max-width: 480px) {
  .header {
    .searchBox {
      width: 100%;
    }
  }
}
